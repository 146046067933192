<template>
  <PageContainer>
    <PageTitle :breadcrumb="{name: 'settings'}" class="w-full lg:w-main-container">
      {{ trans('BUTTON:BackToSettings') }}
    </PageTitle>
    <div class="w-full lg:w-min space-y-3 lg:space-y-6">
      <t-card class="w-full lg:w-main-container">
        <div class="space-y-3">
          <div class="w-full flex flex-col lg:flex-row lg:items-center justify-between">
            <div class="flex w-full justify-start whitespace-nowrap font-semibold">
              {{ trans("LABEL:VerificationStatus") }}
            </div>
            <div class="flex w-full justify-end space-y-3">
              <template v-if="verificationDetails && !verificationDetails[0].verified">
                <template v-if="verificationDetails && verificationDetails.length > 0">
                  <Button
                    :to="{name: 'verification'}"
                    :disabled="verificationDetails[0].init || verificationDetails[0].limit.limitReached"
                    :text="trans('BUTTON:VerificationShow')"
                  />
                </template>
                  <template v-else>
                    <Button
                      @clicked="openShowNoSubscriptionModal(true)"
                      :text="trans('BUTTON:VerificationShow')"
                    />
                  </template>
              </template>
            </div>
          </div>
          <div v-if="userData.isVerified" class="pl-1.5 lg:pl-3 gap-3 lg:gap-6 text-badgeSuccess">
            <div>{{ trans('LABEL:VerificationIsCompleted') }}.</div>
          </div>
          <div v-else>
            <div v-if="verificationDetails" class="gap-3 lg:gap-6">
              <div class="pl-1.5 lg:pl-3 text-badgeError" >
                {{ trans('LABEL:VerificationIsNotCompleted')}}.
              </div>
              <div v-if="verificationDetails.length > 0" class="pl-1.5 lg:pl-3 gap-3 flex flex-col text-base text-badgeError">
                <template v-if="verificationDetails[0].init">
                  <div>
                    {{ trans('LABEL:VerificationCheckLaterComment1') }}
                    <Button
                      @clicked="openContactModal(true)"
                      variant="contactLink"
                      :text="trans('LABEL:VerificationCheckLaterCommentContactLink')"
                    />
                    {{ trans('LABEL:VerificationCheckLaterComment2') }}
                  </div>
                </template>

                <template v-if="verificationDetails[0].rejected && !verificationDetails[0].limit.limitReached
                  || verificationDetails[0].resubmission"
                >
                  <div>
                    {{ trans('LABEL:VerificationTryAgain') }}
                  </div>
                  <div>
                    {{ trans('LABEL:VerificationTryAgainDoubt1') }}
                    <Button
                      @clicked="openContactModal(true)"
                      variant="contactLink"
                      :text="trans('LABEL:VerificationTryAgainDoubtContactLink')"
                    />
                    {{ trans('LABEL:VerificationTryAgainDoubt2') }}
                  </div>
                </template>

                <template v-if="verificationDetails[0].limit.message">
                  <div>
                    {{ transChoice('LABEL:VerificationRejectionLimitExceeded', verificationDetails[0].limit.rejectionLimit) }}
                  </div>
                  <div>
                    {{ trans('LABEL:VerificationContactUs1') }}
                    <Button
                      @clicked="openContactModal(true)"
                      variant="contactLink"
                      :text="trans('LABEL:VerificationContactUsContactLink')"
                    />
                    {{ trans('LABEL:VerificationContactUs2') }}
                  </div>
                </template>
              </div>
            </div>
            <div v-else>
              <Loader small no-border/>
            </div>
          </div>
        </div>
      </t-card>

      <t-card class="w-full lg:w-main-container">
        <div class="flex flex-col space-y-3">
          <div class="w-full font-semibold">
            {{ trans('LABEL:VerificationHistory') }}
          </div>
          <div class="flex flex-col space-y-3 lg:space-y-0 lg:flex-row">
            <div v-if="verificationDetails && verificationDetails.length > 0"
              class="flex flex-col w-full pl-1.5 lg:pl-3"
            >
              <div v-for="(verification, index) in verificationDetails" :key="index"
                class="flex flex-col w-full"
              >
                <hr class="my-3"
                    v-if="index !== verificationDetails.length - 1 && !verification.init && index !== 0"
                >
                <span :class="`text-badge${verification.color} ${verification.init?'pl-1.5 lg:pl-3':''}`">
                  {{ trans(verification.message) }}
                </span>
                <span class="text-base mt-3 pl-1.5 lg:pl-3" v-if="verification.description.message !== null">
                    {{ trans(verification.description.message)}}
                </span>
                <div class="w-full flex flex-row justify-end text-grayedDark text-s whitespace-nowrap">
                  <div class="hidden lg:block">{{ trans('LABEL:SetAt') }}:</div><div>&nbsp;{{ verification.updatedAt }}</div>
                </div>
              </div>
            </div>
            <div v-else class="w-full flex justify-center">
              <Loader small no-border/>
            </div>
          </div>
        </div>
      </t-card>
    </div>
      <Modal
        v-if="isShowContactModal"
        :showModal="isShowContactModal"
        :hide-footer="true"
        @clicked="openContactModal(false)"
        :header="trans('MODAL_TITLE:ContactForm')"
      >
        <template v-slot:content>
          <ContactForm :type="'VERIFICATION'" />
        </template>
      </Modal>

      <Modal
        :showModal="isShowNoSubscriptionModal"
        :buttonConfirmText="trans('BUTTON:GoToSubscriptions')"
        @onConfirm="goToSubscriptions"
        @clicked="openShowNoSubscriptionModal(false)"
        :header="trans('MODAL_TITLE:Attention')"
      >
        <template v-slot:content>
          {{ trans('MODAL_DESCRIPTION:BuySubscriptionToVerify') }}
        </template>
      </Modal>
  </PageContainer>
</template>

<script>
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import {mapActions, mapGetters} from "vuex";
import VeriffForm from "@/views/content/verification/components/VeriffForm";
import Button from "@/utils/crud/components/Button";
import ContactForm from "@/views/content/components/ContactForm";
import Modal from "@/utils/modal/Modal";
import router from "@/router";
import Loader from "@/views/Loader";

export default {
  name: "VerificationDetails",
  components: {Modal, ContactForm, Button, VeriffForm, PageTitle, PageContainer, Loader},
  data() {
    return {
      isShowContactModal: false,
      isShowNoSubscriptionModal: false,
    }
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('verification', ['verificationDetails'])
  },
  methods: {
      ...mapActions('verification', ['fetchVerificationDetails']),
      openShowNoSubscriptionModal(value) {
          this.isShowNoSubscriptionModal = value;
      },
      goToSubscriptions() {
          router.push({name: 'subscription'})
      },
      openContactModal(value) {
          this.isShowContactModal = value;
      }
  },
  mounted() {
    this.fetchVerificationDetails();
  }

}
</script>

